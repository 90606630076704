import React from "react";
import SimpleBanner from "../../Components/User_Component/SimpleBanner";
import TopBanner from "../../Assets/Images/Tour.png";
import Tour_comp1 from "../../Components/User_Component/Tour_component/Tour_comp1";

const Tours = () => {
  return (
    <div>
      <SimpleBanner image={TopBanner} name="Tours" path="" />
      <Tour_comp1 />
    </div>
  );
};

export default Tours;
