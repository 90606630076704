import React from "react";
import logo1 from "../../../Assets/Logos/Vector (5).png";
import logo2 from "../../../Assets/Logos/Vector (6).png";
import logo3 from "../../../Assets/Logos/Vector (7).png";
import logo4 from "../../../Assets/Logos/Vector (8).png";
import bgimage from "../../../Assets/Images/Rectangle 14327.png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const Travel_partner = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  const stats = [
    {
      icon: logo1,
      number: "1000+",
      label: "Hotel",
    },
    {
      icon: logo2,
      number: "2500+",
      label: "Rooms Available",
    },
    {
      icon: logo3,
      number: "20+",
      label: "Beaches View",
    },
    {
      icon: logo4,
      number: "4500+",
      label: "Guests",
    },
  ];

  return (
    <div className="relative w-full px-4 md:px-12 xl:px-20 pt-8 pb-5">
      {/* Background Image */}
      <div className="absolute inset-0 overflow-hidden left-[1050px] bottom-7">
        <img src={bgimage} alt="Background" className=" object-cover" />
      </div>

      {/* Content */}
      <div className="relative max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-16">
          <h2
            className="sm:text-5xl text-4xl font-medium text-[#082358] mb-4 font-dmserif"
            data-aos="fade-down"
          >
            "Your Trusted Travel Partner"
          </h2>
          <p className="text-[#778088] text-lg" data-aos="fade-up">
            Explore our top destinations voted by more than 100,000+
            <br />
            customers around the world.
          </p>
        </div>

        {/* Stats Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => (
            <div
              key={index}
              className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center hover:shadow-xl hover:scale-105 transition duration-200"
            >
              <div
                className="bg-[#6879E2] p-4 rounded-full mb-4"
                data-aos="fade-down"
              >
                <img src={stat.icon} alt={stat.label} className="w-8 h-8" />
              </div>
              <h3
                className="text-3xl font-semibold font-montserrat mb-2"
                data-aos="fade-up"
              >
                {stat.number}
              </h3>
              <p
                className="text-[#778088] text-center font-montserrat"
                data-aos="fade-left"
              >
                {stat.label}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Travel_partner;
