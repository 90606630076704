import React from "react";
import ReviewPage from "../../Components/User_Component/Review_Component/ReviewPage";
import SimpleBanner from "../../Components/User_Component/SimpleBanner";
import TopBanner from "../../Assets/Images/Tour.png";

const Reviews = () => {
  return (
    <div>
      <SimpleBanner image={TopBanner} name="Reviews" path="" />
      <ReviewPage />
    </div>
  );
};

export default Reviews;
