import React from "react";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { FaGoogle } from "react-icons/fa";

const ContactForm = () => {
  return (
    <div className="w-full p mx-auto px-4 md:px-12 xl:px-20 pt-10 pb-10">
      <div className="flex flex-col md:flex-row gap-8">
        <div className="flex-1">
          <div className=" text-gray-500 mb-2 font-semibold text-xl">
            GET IN TOUCH
          </div>
          <h1 className=" sm:text-5xl text-4xl font-medium  mb-4 font-dmserif text-[#082358]">
            Feel Free to Contact with us
          </h1>
          <p className="text-gray-500 mb-6 font-montserrat">
            Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiu
            smod tempor incididunt ut labore dolore magna alique. Quis ultrices
            ipsum suspen ultrices gravida Risus commodo.
          </p>

          <div className="flex gap-4">
            <button className="w-10 h-10 rounded-full bg-blue-600 text-white flex items-center justify-center hover:bg-blue-700">
              <FaFacebookF className="w-5 h-5" />
            </button>
            <button className="w-10 h-10 rounded-full bg-red-600 text-white flex items-center justify-center hover:bg-red-700">
              <FaGoogle className="w-4 h-4" />
              <span className="text-xl">+</span>
            </button>
            <button className="w-10 h-10 rounded-full bg-blue-400 text-white flex items-center justify-center hover:bg-blue-500">
              <FaTwitter className="w-5 h-5" />
            </button>
          </div>
        </div>

        {/* Right Section - Form */}
        <div className="flex-1 bg-gray-50 p-8 rounded-lg font-opensans">
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="text"
                placeholder="Your Name"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="email"
                placeholder="Email Address"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <input
                type="tel"
                placeholder="Phone Number"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <input
                type="text"
                placeholder="Subject"
                className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <textarea
              placeholder="Write Message"
              rows={6}
              className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button
              type="submit"
              className="bg-blue-500 text-white px-8 py-3 rounded-lg hover:bg-blue-600 transition-colors"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
