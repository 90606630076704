import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BiTime } from "react-icons/bi";
import { HiUserGroup } from "react-icons/hi";
import { RiFootprintLine } from "react-icons/ri";
import { BsCalendarDate } from "react-icons/bs";
import img from "../../../Assets/Images/Frame 537.png";
import { FiTag, FiInfo, FiX } from "react-icons/fi";
import { useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { MdClose } from "react-icons/md";
import Faq from "./Faq";
import ReviewCard from "./ReviewCard";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const TourPhotos = () => {
  const [activeTab, setActiveTab] = useState("enquiry");
  const [agreed, setAgreed] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const today = new Date().toISOString().split("T")[0];
  const navigate = useNavigate();

  const priceIncludes = [
    "Welcome drink on arrival (Non-alcoholic)",
    "Breakfast Included",
    "Transportation by well-condition AC Car as per your tour",
    "Driver allowance, toll tax, parking and state Govt. tax",
    "Airport Pick up and Drop",
    "Hotel Taxes",
  ];

  const priceExcludes = [
    "Monument entry fees",
    "Porter age at hotels and airports, tips, insurance",
    "Laundry, liquors, wine, telephone charges",
    "Air / Train fare",
    "Any other which was not mention on tour inclusion",
  ];

  const [tourData, setTourData] = useState(() => {
    const savedTour = localStorage.getItem("selectedTour");
    return savedTour ? JSON.parse(savedTour) : null;
  });

  const handleBackClick = () => {
    localStorage.clear();
    navigate(-1);
  };

  console.log(tourData);
  return (
    <div className="w-full px-4 md:px-12 xl:px-20 overflow-hidden pt-6 pb-8">
      <h1 className="sm:text-5xl text-3xl text-[#082358] font-dmserif flex justify-start items-center gap-3">
        <div
          className="p-2 rounded-full hover:bg-gray-300 cursor-pointer"
          onClick={() => {
            handleBackClick();
          }}
        >
          <MdArrowBackIos size={38} />{" "}
        </div>
        <p>{tourData?.title}</p>
      </h1>
      <div className="flex justify-start items-center gap-2 text-yellow-400 sm:pt-7 pt-3">
        {[...Array(5)].map((_, index) => (
          <div key={index}>
            {index < tourData?.rating ? (
              <AiFillStar size={22} />
            ) : (
              <AiOutlineStar size={22} />
            )}
          </div>
        ))}
      </div>
      <div className="flex flex-wrap sm:items-center items-start justify-start sm:gap-8 gap-3 sm:flex-row flex-col pt-7 bg-white rounded-lg shadow-sm">
        <div className="flex items-center gap-2 text-gray-700">
          <BiTime size={24} />
          <span className="text-xl">Duration : {tourData?.duration}</span>
        </div>

        <div className="flex items-center gap-2 text-gray-700">
          <HiUserGroup size={24} />
          <span className="text-xl">Group Size : 1</span>
        </div>

        <div className="flex items-center gap-2 text-gray-700">
          <RiFootprintLine size={24} />
          <span className="text-xl">Tour Type : Daily Tour</span>
        </div>

        {/* <div className="flex items-center gap-2 text-gray-700">
          <BsCalendarDate size={24} />
          <span className="text-xl">Jan - July</span>
        </div> */}
      </div>
      <div className="flex flex-col md:flex-row gap-4 w-full pt-7 mx-auto ">
        {/* Main Featured Image */}
        <div className="relative w-full ">
          <img
            src={tourData?.image}
            alt={tourData?.title}
            className="w-full h-[500px] object-cover rounded-lg"
          />
        </div>

        {/* Side Gallery */}
      </div>

      <div className="flex sm:gap-16 gap-5 w-full mx-auto pt-10 lg:flex-row flex-col">
        {/* Left Column - Price Details */}
        <div className="lg:w-2/3 w-full font-opensans">
          <div className="flex  items-start flex-col gap-4 ">
            <p className="text-3xl text-[#082358] font-dmserif">Description</p>
            <p className="text-xl text-[#6C6C6C] sm:text-justify tracking-wide leading-normal">
              {tourData?.description}
            </p>
          </div>
          {/* Price Includes Section */}
          <div className="mb-8 flex justify-start sm:items-center items-start sm:gap-72 pt-8 md:flex-row flex-col gap-6">
            <h3 className="text-xl font-medium sm:mb-4">Price Includes</h3>
            <ul className="space-y-3">
              {priceIncludes.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center gap-2 text-lg text-[#6C6C6C]"
                >
                  <IoCheckmarkOutline size={20} className="text-blue-500" />
                  {item}
                </li>
              ))}
            </ul>
          </div>

          {/* Price Excludes Section */}
          <div className="flex justify-start sm:items-center items-start sm:gap-72 md:flex-row gap-6 flex-col">
            <h3 className="text-xl font-medium mb-4">Price Excludes</h3>
            <ul className="space-y-3">
              {priceExcludes.map((item, index) => (
                <li
                  key={index}
                  className="flex items-center gap-2 text-lg text-[#6C6C6C]"
                >
                  <MdClose size={20} className="text-red-500" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
          <Faq data={tourData?.itinerary?.length ? tourData : null} />
        </div>

        <div className="lg:w-1/3 w-full">
          <div className="mb-6">
            <div className="flex justify-between items-center">
              <h2 className="text-3xl font-medium font-dmserif text-[#082358]">
                Price
              </h2>
              <div className="flex items-center gap-2 font-dmserif text-[#082358]">
                <FiTag className="text-gray-600" />
                <span className="text-gray-600">From</span>
                <span className="text-2xl font-bold">{tourData.price}</span>
                <FiInfo className="text-gray-400 cursor-help" />
              </div>
            </div>
          </div>

          <div className="font-opensans">
            <div className="flex gap-4 border-b mb-6">
              {/* <button
                onClick={() => setActiveTab("booking")}
                className={`pb-2 px-1 ${
                  activeTab === "booking"
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500"
                }`}
              >
                Booking Form
              </button> */}
              <button
                onClick={() => setActiveTab("enquiry")}
                className={`pb-2 px-1 ${
                  activeTab === "enquiry"
                    ? "border-b-2 border-blue-500 text-blue-500"
                    : "text-gray-500"
                }`}
              >
                Enquiry Form
              </button>
            </div>

            {/* Booking Form */}
            {/* {activeTab === "booking" && (
              <form className="space-y-6">
                <div>
                  <input
                    type="text"
                    placeholder="Full Name*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <input
                    type="tel"
                    maxLength={10}
                    placeholder="Phone Number*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                  />
                </div>
                <div className="flex gap-4">
                  <div className="w-1/2">
                    <label className="block text-sm text-gray-600 mb-1">
                      Start Date*
                    </label>
                    <input
                      type="date"
                      min={today}
                      value={startDate}
                      onChange={(e) => setStartDate(e.target.value)}
                      className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-sm text-gray-600 mb-1">
                      End Date*
                    </label>
                    <input
                      type="date"
                      min={startDate || today}
                      value={endDate}
                      onChange={(e) => setEndDate(e.target.value)}
                      className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm text-gray-600 mb-1">
                    No. of Persons*
                  </label>
                  <select className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500">
                    <option value="">Select No. of Persons</option>
                    {[...Array(6)].map((_, i) => (
                      <option key={i} value={i + 1}>
                        {i + 1}
                      </option>
                    ))}
                    <option value="7+">7+</option>
                  </select>
                </div>

                
                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
                >
                  SUBMIT BOOKING
                </button>
              </form>
            )} */}

            {/* Enquiry Form */}
            {activeTab === "enquiry" && (
              <form className="space-y-6">
                <div>
                  <input
                    type="text"
                    placeholder="Full Name*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <input
                    type="email"
                    placeholder="Email Address*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <input
                    type="number"
                    placeholder="Phone*"
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500"
                    required
                  />
                </div>
                <div>
                  <textarea
                    placeholder="Your Enquiry*"
                    rows={4}
                    className="w-full p-3 border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 resize-none"
                    required
                  />
                </div>

                <div className="flex items-start gap-2">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={agreed}
                    onChange={(e) => setAgreed(e.target.checked)}
                    className="mt-1"
                  />
                  <label htmlFor="terms" className="text-gray-600 text-sm">
                    * I agree with{" "}
                    <a href="#" className="text-gray-700 hover:underline">
                      Terms of Service
                    </a>{" "}
                    and{" "}
                    <a href="#" className="text-gray-700 hover:underline">
                      Privacy Statement
                    </a>
                    .
                  </label>
                </div>

                {/* Submit Button */}
                <button
                  type="submit"
                  className="w-full py-3 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors disabled:bg-blue-300"
                  disabled={!agreed}
                >
                  SUBMIT ENQUIRY
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* <ReviewCard /> */}
    </div>
  );
};

export default TourPhotos;
