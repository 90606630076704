import React from "react";
import puriimg1 from "../../Assets/Images/Galleryimgs/Jagannathtemple1.jpg";
import puriimg2 from "../../Assets/Images/Galleryimgs/dilip-poddar-a4KEI6SYy10-unsplash.jpg";
import puriimg3 from "../../Assets/Images/Galleryimgs/puriimg3.jpg";
import puriimg4 from "../../Assets/Images/Galleryimgs/puriimg4.jpg";
import puriimg5 from "../../Assets/Images/Galleryimgs/konark1.jpg";
import puriimg6 from "../../Assets/Images/Galleryimgs/konark2.jpg";
import puriimg7 from "../../Assets/Images/Galleryimgs/konark3.jpg";
import puriimg8 from "../../Assets/Images/Galleryimgs/konark8.jpg";
import chilika1 from "../../Assets/Images/Galleryimgs/chilika1.jpg";
import chilika2 from "../../Assets/Images/Galleryimgs/chilika2.jpg";
import Daringbadi1 from "../../Assets/Images/Galleryimgs/Daringbadi1.jpg";
import Daringbadi2 from "../../Assets/Images/Galleryimgs/Daringbadi2.jpg";
import deomali1 from "../../Assets/Images/Galleryimgs/deomali1.jpg";
import deomali2 from "../../Assets/Images/Galleryimgs/deomali2.jpg";
import birajamandir1 from "../../Assets/Images/Galleryimgs/birajamandir1.jpg";
import birajamandir2 from "../../Assets/Images/Galleryimgs/birajamandir2.jpg";
import SimpleBanner from "../../Components/User_Component/SimpleBanner";
import TopBanner from "../../Assets/Images/Tour.png";

const Gallery = () => {
  const images = [
    { img: puriimg1 },
    { img: puriimg2 },
    { img: puriimg3 },
    { img: puriimg4 },
    { img: puriimg5 },
    { img: puriimg6 },
    { img: puriimg7 },
    { img: puriimg8 },
    { img: chilika1 },
    { img: chilika2 },
    { img: Daringbadi1 },
    { img: Daringbadi2 },
    { img: deomali1 },
    { img: deomali2 },
    { img: birajamandir1 },
    { img: birajamandir2 },
  ];

  return (
    <>
      <SimpleBanner image={TopBanner} name="Gallery" path="" />
      <div className="py-10 px-4 md:px-12 xl:px-20">
        <div className="text-center mb-12">
          <h3
            className="text-[#4691F2] font-light mb-2 sm:text-4xl text-3xl font-aguafina tracking-widest"
            data-aos="fade-down"
          >
            Gallery
          </h3>
          <h2
            className="sm:text-5xl text-4xl text-[#082358] font-medium font-dmserif max-w-6xl mx-auto"
            data-aos="fade-up"
          >
            Our Travel Memories.
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-4">
          {images?.map((i) => {
            return (
              <div>
                <img
                  src={i.img}
                  alt="images of different tours"
                  className="rounded-lg h-[250px] w-full hover:shadow-lg flex justify-items-start hover:scale-[1.02] transition duration-200 font-montserrat border-[2px] object-cover"
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Gallery;
