import React from "react";
import bgimage from "../../../Assets/Images/Sectionbg.png";
import { FaArrowRightLong } from "react-icons/fa6";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import { useNavigate } from "react-router-dom";

const Rememberus = () => {
  const navigate = useNavigate();
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  return (
    <div
      className="relative bg-cover bg-center text-white py-20 px-10"
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <div className="text-center max-w-4xl mx-auto">
        <h1
          className="sm:text-5xl text-4xl font-medium mb-4 font-dmserif"
          data-aos="fade-down"
        >
          Are You Ready To Travel? <br />
          <span>Remember Us !!</span>
        </h1>
        <p
          className="text-base mb-8 leading-relaxed font-montserrat"
          data-aos="fade-right"
        >
          Amet nisl purus in mollis nunc. Blandit libero volutpat sed cras
          ornare. In pellentesque massa placerat duis ultricies lacus sed. Nisl
          suscipit adipiscing bibendum est ultricies integer quis auctor. Id
          consectetur purus ut faucibus pulvinar elementum integer enim.
        </p>
        <button
          className="bg-white hover:bg-blue-600 text-blue-600 hover:text-white py-2 px-6 rounded-full shadow-md flex justify-center items-center gap-3 mx-auto cursor-pointer"
          onClick={() => {
            navigate("/tours");
            window.scrollTo({ top: 0, behavior: "smooth" });
          }}
          data-aos="fade-up"
        >
          Book Now
          <FaArrowRightLong />
        </button>
      </div>
    </div>
  );
};

export default Rememberus;
