import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { GiHamburgerMenu } from "react-icons/gi";
import Logo from "../../Assets/Logos/Logo.png";
import MainLogo from "../../Assets/Images/mainLogoFooter.png";

// import textImg from "../../Assets/common/a-key-text.png";
import { FaPhoneVolume } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { RiFacebookFill } from "react-icons/ri";
import { IoLogoTwitter } from "react-icons/io5";
import { FaYoutube } from "react-icons/fa";
import { PiInstagramLogoFill } from "react-icons/pi";

const Navbar = () => {
  const [activeMenuItem, setActiveMenuItem] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        setActiveMenuItem(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeMenuItem]);

  const toggleDropdownOptions = (menuItem) => {
    setActiveMenuItem(activeMenuItem === menuItem ? null : menuItem);
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  // const handleItemClick = (path) => {
  //   navigate(path);
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  //   setMenuOpen(false); // Close the menu after item click
  // };

  const handleItemClick = (path, title) => {
    setActiveMenuItem(title);
    navigate(path);
    window.scrollTo({ top: 0, behavior: "smooth" });
    setMenuOpen(false);
  };

  const menuItems = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "About Us",
      path: "/about",
    },
    {
      title: "Tours",
      path: "/tours",
    },
    {
      title: "Reviews",
      path: "/reviews",
    },
    {
      title: "Gallery",
      path: "/gallery",
    },
  ];

  console.log("Active menu Item: ", activeMenuItem);

  return (
    <div>
      <HeaderNav />
      {/* desktop view */}
      <div className="bg-white px-6 md:px-10 xl:px-20 py-1 xl:flex hidden items-center justify-between shadow-md">
        {/* Logo */}
        <div>
          <img src={Logo} alt="Logo" className="w-56 h-auto" />
        </div>
        {/* Menu items */}
        <div className="flex gap-10 xl:gap-16 items-center">
          {menuItems.map((item, index) => (
            <div
              key={index}
              className={`flex items-center gap-1 cursor-pointer relative ${
                activeMenuItem === item.title
                  ? "text-[#4691F2]"
                  : "text-[#6C6C6C]"
              } hover:text-[#4691F2] font-semibold xl:text-lg`}
              onClick={() =>
                item.options
                  ? toggleDropdownOptions(item.title)
                  : handleItemClick(item.path, item.title)
              }
            >
              {item.title}
            </div>
          ))}
        </div>
        {/* button */}
        <div>
          <button
            onClick={() => {
              navigate("/contact");
              window.scrollTo(0, 0);
            }}
            className="bg-[#4691F2] hover:bg-[#23436d] text-white px-8 py-3 rounded-md text-lg"
          >
            Contact us
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      <div className="bg-[#0D0D0D] px-10 py-3 xl:hidden flex items-center justify-between shadow-lg">
        {/* Logo */}
        <div>
          <img src={MainLogo} alt="logo" className=" w-32 h-auto" />
        </div>
        {/* Menu option */}
        <div onClick={toggleMenu}>
          <GiHamburgerMenu size={30} className="text-white" />
        </div>
      </div>
      <AnimatePresence>
        {menuOpen && (
          <div className="fixed inset-0 bg-transparent flex justify-end z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.9, x: 100 }}
              animate={{ opacity: 1, scale: 1, x: 0 }}
              exit={{ opacity: 0, scale: 0.9, x: 100 }}
              transition={{ duration: 0.2 }}
              ref={menuRef}
              className="bg-white shadow-lg textprimaryTextColor p-5 w-[90%] md:w-[60%] flex flex-col justify-between"
            >
              <div>
                {menuItems.map((item, index) => (
                  <div
                    key={index}
                    className="flex flex-col items-start gap-1 relative"
                    onClick={() =>
                      item.options
                        ? toggleDropdownOptions(item.title)
                        : handleItemClick(item.path, item.title)
                    }
                  >
                    <div className="flex items-center mb-3 cursor-pointer text-[20px]">
                      {item.title}
                    </div>
                  </div>
                ))}
              </div>

              <div className="flex justify-between gap-4 items-center">
                <button
                  className="bg-[#4691F2] hover:bg-[#466389] text-white px-4 py-2 rounded-md"
                  onClick={() => navigate("/contact")}
                >
                  Contact us
                </button>

                <button
                  className="bg-gray-400 text-white px-4 py-2 rounded-md"
                  onClick={() => setMenuOpen(false)}
                >
                  Close
                </button>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Navbar;

const HeaderNav = () => {
  return (
    <div className="hidden lg:flex h-12">
      <div className="relative w-full flex">
        {/* Left section */}
        <div className="bg-[#4691F2] w-full text-white h-full flex justify-start items-center gap-6 px-20">
          <div className="flex justify-center items-center gap-2">
            <FaPhoneVolume size={20} />
            <p className="text-md">+91 9583300300</p>
          </div>
          <div className="flex justify-center items-center gap-2">
            <IoMdMail size={22} />
            <p className="text-lg">ananyatravel1996@gmail.com</p>
          </div>
        </div>

        {/* Right section with diagonal */}
        <div className="bg-[#082358] w-full h-full relative">
          {/* Diagonal element */}
          <div
            className="absolute top-0 -left-14 h-full w-16 bg-[#082358]"
            style={{ transform: "skew(-20deg)" }}
          />
          {/* Content */}
          <div className="h-full flex justify-end items-center gap-3 px-20 relative ">
            <div className="w-[2px] h-7 bg-white"></div>
            <div className="p-2 rounded-full bg-white text-[#4691F2]">
              <RiFacebookFill size={18} />
            </div>
            <div className="p-2 rounded-full bg-white text-[#4691F2]">
              <IoLogoTwitter size={18} />
            </div>
            <div className="p-2 rounded-full bg-white text-[#4691F2]">
              <FaYoutube size={18} />
            </div>
            <div className="p-2 rounded-full bg-white text-[#4691F2]">
              <PiInstagramLogoFill size={18} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
