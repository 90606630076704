import React from "react";
import bgimage from "../../Assets/Images/bgfooter2.png";
import MainLogo from "../../Assets/Images/mainLogoFooter.png";
import { FaPhoneVolume } from "react-icons/fa6";
import { MdMailOutline } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Footer_Component2 = () => {
  const navigate = useNavigate();
  return (
    <footer
      className="text-white py-12 relative mt-6"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container mx-auto px-4 pt-20 font-montserrat">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="space-y-4 font-montserrat">
            <div className="flex items-center space-x-2">
              <img src={MainLogo} alt="Ananya Logo" className=" h-20" />
            </div>
            <p className="text-gray-300">
              Discover amazing places at exclusive deals.
            </p>
            <p className="text-gray-300">
              Eat, Shop, Visit interesting places around the world.
            </p>
            <button
              className="bg-white hover:bg-blue-600 text-blue-600 hover:text-white py-2 px-6 rounded-full shadow-md flex justify-center items-center gap-3"
              onClick={() => {
                navigate("/tours");
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
            >
              Book Now
              <FaArrowRightLong />
            </button>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-white">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about" className="text-gray-300 hover:text-white">
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/tours" className="text-gray-300 hover:text-white">
                  Tours
                </Link>
              </li>
              <li>
                <Link to="/reviews" className="text-gray-300 hover:text-white">
                  Reviews
                </Link>
              </li>
              <li>
                <Link to="/gallery" className="text-gray-300 hover:text-white">
                  Gallery
                </Link>
              </li>
              <li>
                <Link to="/contact" className="text-gray-300 hover:text-white">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-4">Get In Touch</h3>
            <div className="space-y-3">
              <div className="flex items-center space-x-2">
                <div className="p-2 bg-[#4691F2] text-white rounded-full">
                  <span>
                    <FaPhoneVolume size={14} />
                  </span>
                </div>
                <span>+91 9583300300</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="p-2 bg-[#4691F2] text-white rounded-full">
                  <span>
                    <MdMailOutline size={18} />
                  </span>
                </div>
                <span>ananyatravel1996@gmail.com</span>
              </div>
              <div className="flex items-center space-x-2">
                <div className="p-2 bg-[#4691F2] text-white rounded-full">
                  <span>
                    <FaLocationDot size={14} />
                  </span>
                </div>
                <span>
                  prachi enclave, Anmol plaza, near Care Hospital Road, District
                  Center, Chandrasekharpur, Bhubaneswar, Odisha 751016
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-700 mt-8 pt-4 flex flex-col md:flex-row justify-between items-center">
          <p className="text-sm text-gray-400">
            ANANYATRAVELS © 2021 All Right Reserved
          </p>
          <div className="flex space-x-4 mt-4 md:mt-0">
            <a href="#" className="text-sm text-gray-400 hover:text-white">
              Terms of Service
            </a>
            <span className="text-gray-400">|</span>
            <a href="#" className="text-sm text-gray-400 hover:text-white">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer_Component2;
