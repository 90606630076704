import React from "react";

const Contacts = () => {
  return (
    <div className="py-8 sm:pt-7 bg-gray-100 font-montserrat min-h-[400px] flex items-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-14">
          {/* Location Card */}
          <div className="bg-white shadow-xl rounded-2xl p-4 sm:p-6 text-center border border-gray-300 flex flex-col justify-center">
            <h4 className="font-semibold text-lg sm:text-xl mt-4 sm:mt-6 mb-2 text-[#082358]">
              Our Location
            </h4>
            <p className="text-gray-600 text-sm sm:text-base capitalize">
              prachi enclave, Anmol plaza, near Care Hospital Road, District
              Center, Chandrasekharpur, Bhubaneswar, Odisha 751016
            </p>
          </div>

          {/* Email Card */}
          <div className="bg-white shadow-xl rounded-2xl p-4 sm:p-6 text-center border border-gray-300 flex flex-col justify-center">
            <h4 className="font-semibold text-lg sm:text-xl mt-4 sm:mt-6 mb-2 text-[#082358]">
              Email Address
            </h4>
            <p className="text-gray-600 text-sm sm:text-base break-words">
              ananyatravel1996@gmail.com
            </p>
          </div>

          {/* Phone Card */}
          <div className="bg-white shadow-xl rounded-2xl p-4 sm:p-6 text-center border border-gray-300 flex flex-col justify-center">
            <h4 className="font-semibold text-lg sm:text-xl mt-4 sm:mt-6 mb-2 text-[#082358]">
              Phone Number
            </h4>
            <p className="text-gray-600 text-sm sm:text-base">+91 9583300300</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
