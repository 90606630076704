import React from "react";
import SimpleBanner from "../../Components/User_Component/SimpleBanner";
import TopBanner from "../../Assets/Images/Tour.png";
import AboutUsHome from "../../Components/User_Component/Home_Component/About_us_home";
import FaqsAbout from "../../Components/User_Component/About_us_Component/FaqsAbout";
import Guides from "../../Components/User_Component/About_us_Component/Guides";
import LastComp from "../../Components/User_Component/About_us_Component/LastComp";

const About = () => {
  return (
    <div className="overflow-x-hidden">
      <SimpleBanner image={TopBanner} name="About" path="" />
      <AboutUsHome />
      <FaqsAbout />
      <Guides />
      <LastComp />
    </div>
  );
};

export default About;
