import React from "react";
import SimpleBanner from "../../Components/User_Component/SimpleBanner";
import TopBanner from "../../Assets/Images/Tour.png";
import Contacts from "../../Components/User_Component/ContactComponents/Contacts";
import ContactForm from "../../Components/User_Component/ContactComponents/ContactForm";
import Map from "../../Components/User_Component/ContactComponents/Map";

const Contact = () => {
  return (
    <div>
      <SimpleBanner image={TopBanner} name="Contact" path="" />
      <Contacts />
      <Map />
      <ContactForm />
    </div>
  );
};

export default Contact;
