import React, { useState, useEffect } from "react";
import { BiSearch, BiMap } from "react-icons/bi";
import { BsPeople, BsCalendar } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa";
import "aos/dist/aos.css";
import AOS from "aos";

// Import your background image
import Bgimage from "../../../Assets/Images/Section@2x.png";

const HeroSection = () => {
  // State for form data
  const [formData, setFormData] = useState({
    destination: "",
    guests: "",
    date: "",
  });

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
  };

  const handleWhatsAppRedirect = () => {
    window.open("https://wa.me/+919583300300", "_blank", "noopener,noreferrer");
  };

  return (
    <div className="relative">
      {/* Hero Section */}
      <div
        className="relative min-h-[550px] w-full"
        style={{
          backgroundImage: `url(${Bgimage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        data-aos="zoom-in"
      >
        {/* WhatsApp Button */}
        <div className="fixed right-1 sm:bottom-20 bottom-48 z-50">
          <button
            onClick={handleWhatsAppRedirect}
            className="bg-green-500 hover:bg-green-600 text-white sm:px-4 sm:py-4 px-2 py-2 rounded-full shadow-lg flex justify-center items-center gap-3 transition-colors"
          >
            <FaWhatsapp size={30} />
          </button>
        </div>

        {/* Hero Content */}
        <div className="container mx-auto px-4 sm:pt-32 pt-16">
          {/* Agency Name */}
          <p
            className="text-gray-300 mb-2 font-aguafina md:text-3xl text-xl tracking-widest text-justify"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="300"
          >
            Tour & Travel Agency
          </p>

          {/* Main Heading */}
          <h1
            className="xl:text-7xl text-4xl md:text-5xl font-bold text-white mb-2 font-serif tracking-wide pt-2"
            data-aos="zoom-in"
            data-aos-duration="1500"
            data-aos-delay="200"
          >
            Find Next Place
            <br />
            To <span className="text-blue-400">Visit</span>
          </h1>

          {/* Subheading */}
          <div className="max-w-lg">
            <p
              className="text-gray-300 mb-4 font-montserrat leading-normal tracking-wide md:text-lg text-md"
              data-aos="fade-up"
            >
              Discover amazing places at exclusive deals. Eat, Shop, Visit
              interesting places around the world.
            </p>
          </div>
        </div>
      </div>

      {/* Search Form Section */}
      <div className="absolute left-0 right-0 sm:bottom-[-50px] px-4 font-montserrat z-10 bottom-[-200px] w-full">
        <div className="container mx-auto lg:w-[75%] w-full">
          <div className="bg-white rounded-lg shadow-lg">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-0">
                {/* Location Field */}
                <div className="p-4 sm:p-6 border-b sm:border-b lg:border-b-0 lg:border-r border-gray-200">
                  <div className="flex items-center gap-2 mb-2">
                    <BiMap className="text-[#082358] min-w-[22px]" size={22} />
                    <p className="block text-gray-800 text-base sm:text-lg font-semibold">
                      Location
                    </p>
                  </div>
                  <input
                    type="text"
                    name="destination"
                    placeholder="Search for a destination"
                    className="w-full p-2 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.destination}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Guests Field */}
                <div className="p-4 sm:p-6 border-b sm:border-b lg:border-b-0 lg:border-r border-gray-200">
                  <div className="flex items-center gap-2 mb-2">
                    <BsPeople
                      className="text-[#082358] min-w-[22px]"
                      size={22}
                    />
                    <p className="block text-gray-800 text-base sm:text-lg font-semibold">
                      Guests
                    </p>
                  </div>
                  <input
                    type="number"
                    name="guests"
                    min="1"
                    placeholder="Number of guests"
                    className="w-full p-2 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.guests}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Date Field */}
                <div className="p-4 sm:p-6 border-b sm:border-b lg:border-b-0 lg:border-r border-gray-200">
                  <div className="flex items-center gap-2 mb-2">
                    <BsCalendar
                      className="text-[#082358] min-w-[22px]"
                      size={22}
                    />
                    <p className="block text-gray-800 text-base sm:text-lg font-semibold">
                      Date
                    </p>
                  </div>
                  <input
                    type="date"
                    name="date"
                    className="w-full p-2 text-sm border border-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                    value={formData.date}
                    onChange={handleInputChange}
                  />
                </div>

                {/* Search Button */}
                <div className="p-4 sm:p-6 flex items-center justify-center">
                  <button
                    type="submit"
                    className="w-full bg-blue-500 text-white px-4 sm:px-6 py-3 rounded-lg flex items-center justify-center gap-2 hover:bg-blue-600 transition-colors"
                  >
                    <BiSearch size={24} />
                    <span className="text-sm sm:text-base">Search Now</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
