import React from "react";
import img1 from "../../../Assets/Images/Vehiclepageimage.png";
import { AiOutlineArrowRight } from "react-icons/ai";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const Vehicles = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  return (
    <div className="flex flex-col lg:flex-row items-center justify-between gap-4 bg-white py-10 pl-4 sm:pl-12 xl:pl-20">
      <div className="lg:w-1/2 mb-8 lg:mb-0  ">
        <h3
          className="text-[#4691F2] sm:text-4xl text-3xl font-medium  font-aguafina tracking-widest"
          data-aos="fade-down"
        >
          Vehicles
        </h3>
        <h1
          className="sm:text-5xl text-4xl font-medium text-[#082358]  font-dmserif "
          data-aos="fade-right"
        >
          Dealing with Transportation
          <br />
          <span>Arrangements .</span>
        </h1>
        <p
          className="text-[#778088] mb-6 font-montserrat sm:text-xl text-lg pt-2"
          data-aos="fade-right"
        >
          Amet cursus sit amet dictum. At erat pellentesque adipiscing commodo.
          Sit amet volutpat consequat mauris nunc congue nisi vitae suscipit.
        </p>
        <ul className="space-y-2 font-opensans  ">
          <li className="flex items-center  gap-3" data-aos="fade-right">
            <AiOutlineArrowRight className="text-blue-500 " size={20} />
            <span className="sm:text-lg text-md">
              <span className=" font-semibold ">Booking Travel:</span>{" "}
              <span className="text-[#778088] ">
                Reserving flights, trains, buses, or private vehicles as per the
                itinerary.
              </span>
            </span>
          </li>
          <li className="flex items-center gap-3" data-aos="fade-right">
            <AiOutlineArrowRight className="text-blue-500 " size={20} />
            <span className="sm:text-lg text-md">
              <span className="font-semibold ">Sightseeing:</span>{" "}
              <span className="text-[#778088] ">
                Providing guided transport for local tours and sightseeing.
              </span>
            </span>
          </li>
          <li className="flex items-center gap-3" data-aos="fade-right">
            <AiOutlineArrowRight className="text-blue-500 " size={20} />
            <span className="sm:text-lg text-md">
              <span className=" font-semibold ">Vehicle Options:</span>{" "}
              <span className="text-[#778088] ">
                Offering a range of vehicles, from budget cars to luxury buses,
                to suit customer preferences.
              </span>
            </span>
          </li>
          <li className="flex items-center gap-3">
            <AiOutlineArrowRight className="text-blue-500 " size={20} />
            <span className="sm:text-lg text-md">
              <span className=" font-semibold ">Convenience:</span>{" "}
              <span className="text-[#778088] ">
                Ensuring timely, comfortable, and reliable services to make
                travel seamless.
              </span>
            </span>
          </li>
        </ul>
      </div>

      <div className="lg:w-1/2" data-aos="fade-left">
        <img
          src={img1}
          alt="Vehicle arrangement options"
          className="rounded-2xl shadow-lg w-full object-cover"
        />
      </div>
    </div>
  );
};

export default Vehicles;
