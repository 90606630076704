import React from "react";
import bgimage from "../../../Assets/Images/aboutushomeimg.png";
import img1 from "../../../Assets/Images/image 43.png";
import img2 from "../../../Assets/Images/Frame 502.png";
import img3 from "../../../Assets/Images/Frame 501.png";
import bigimage from "../../../Assets/Images/Frame 499.png";
import { FaChevronRight, FaPlane } from "react-icons/fa";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const AboutUsHome = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  const points = [
    "Offers Assistance for Individual, Group, and Corporate Travel Needs.",
    "Ensures Quality and Budget-Friendly Accommodations.",
    "Ensures Seamless Management of Guided Tours.",
  ];
  return (
    <div
      className="relative bg-cover bg-center text-gray-800 py-8 px-6"
      style={{ backgroundImage: `url(${bgimage})` }}
    >
      <div className="max-w-7xl mx-auto grid grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 gap-4">
        <div className=" p-4 col-span-1">
          <div className="grid grid-cols-5 gap-4">
            <div className="space-y-4 col-span-3">
              <div>
                <img
                  src={img1}
                  alt="Left top"
                  className="rounded-lg shadow-md"
                  data-aos="fade-down"
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <img
                  src={img2}
                  alt="Left bottom 1"
                  className="rounded-lg shadow-md col-span-1"
                  data-aos="fade-right"
                />
                <img
                  src={img3}
                  alt="Left bottom 2"
                  className="rounded-lg shadow-md col-span-1"
                  data-aos="fade-left"
                />
              </div>
            </div>

            {/* Right big image */}
            <div className="col-span-2 ">
              <img
                src={bigimage}
                alt="Right big"
                className="rounded-lg shadow-md w-full h-full object-cover"
                data-aos="fade-up"
              />
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-center space-y-4 col-span-1">
          <div>
            <h2
              className="text-[#4691F2] font-medium mb-3 sm:text-4xl text-3xl font-aguafina tracking-widest"
              data-aos="fade-down"
            >
              About Us
            </h2>
            <h3
              className="sm:text-5xl text-4xl font-medium font-dmserif text-[#082358]"
              data-aos="fade-right"
            >
              Travel Smart, Travel Happy.
            </h3>
          </div>

          <p
            className="text-[#778088] leading-relaxed font-montserrat tracking-wide sm:text-xl text-lg space-y-2"
            data-aos="fade-up"
          >
            Experience the wonders of cultural heritage and natural beauty
            across Asia. From ancient temples to serene landscapes, we curate
            journeys that connect you with the soul of each destination.
          </p>

          <div>
            <ul className="space-y-4">
              {points.map((point, index) => (
                <li
                  key={index}
                  className="flex items-start"
                  data-aos="fade-left"
                >
                  <FaChevronRight className="text-blue-500 mr-2 mt-1" />
                  <span className="text-gray-800 font-medium sm:text-xl text-lg font-urbanist">
                    {point}
                  </span>
                </li>
              ))}
            </ul>
            {/* Arrow button */}
            <div
              className="flex justify-between items-end mt-6 sm:pr-10"
              data-aos="fade-right"
            >
              <div></div>
              <button className="bg-blue-500 p-3 rounded-full text-white shadow-md">
                <FaChevronRight />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsHome;
