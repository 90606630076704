import React from "react";

const Map = () => {
  return (
    <div className="w-full h-[50vh] md:h-[60vh] lg:h-[75vh] px-4 md:px-12 xl:px-20">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8075.713284683704!2d85.81240772634922!3d20.323852436103863!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a1909a930000001%3A0xc785f31900c7113e!2sAnmol%20Plaza%2C%20Prachi%20Enclave!5e0!3m2!1sen!2sin!4v1739190423314!5m2!1sen!2sin"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Map;
