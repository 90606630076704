import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { FaBus } from "react-icons/fa";
import { BiTime } from "react-icons/bi";
import img1 from "../../../Assets/Images/Frame 537.png";
import img2 from "../../../Assets/Images/Chilika.webp";

import img3 from "../../../Assets/Images/jagannath-puri-temple.jpg";

import img4 from "../../../Assets/Images/odisha-tourism-2.png";
import img5 from "../../../Assets/Images/daringbadi.jpg";
import img6 from "../../../Assets/Images/deomali.jpeg";
import img7 from "../../../Assets/Images/BirajaDeviMandir.jpg";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const PopularPlacesOdisha = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  const places = [
    {
      title: "Bhubaneswar Konark Puri Chilika Tour Package",
      image: img1,
      rating: 4,
      description:
        "Odisha, a captivating destination, boasts over 7,000 temples, including Lingaraj and Rajarani. Its tropical climate, vibrant atmosphere, and stunning Kalinga architecture make it a spiritual and architectural marvel.",
    },
    {
      title: "3N 4D Bhubaneswar Konark Puri Chilika Tour",
      image: img2,
      rating: 4,
      description:
        "All the spectacular tourist destinations in one tour package – that’s what we bring to you here. Witness beauty, divinity, wonderful heritage, beaches, good roads and best views, packed in this one-of-its-kind trip!",
    },
    {
      title: "Odisha Fixed Departure Tour",
      image: img4,
      rating: 4,
      description:
        "Explore Odisha's iconic temples, including Lingaraj and Rajarani. Witness stunning Kalinga architecture, vibrant culture, and serene landscapes on a well-planned tour offering unforgettable spiritual and cultural experiences.",
    },
    {
      title: "Jagannath Puri Tour Package",
      image: img3,
      rating: 4,
      description:
        "Experience the divine aura of Jagannath Temple, explore serene beaches, and immerse yourself in Odisha's vibrant culture with this thoughtfully crafted tour for a memorable spiritual and cultural journey.",
    },
    {
      id: 5,
      title: "Daringbadi Tour Package",
      image: img5,
      rating: 4.5,
      description: `Daringbadi, the "Kashmir of Odisha," is a serene hill station with lush forests, waterfalls, and coffee plantations. Its cool climate and tribal culture make it a perfect getaway for nature lovers.`,
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹899",
      locations: ["Daringbadi"],
    },
    {
      id: 6,
      title: "Deomali Tour Package",
      image: img6,
      rating: 4,
      description:
        "Deomali, the highest peak in Odisha, offers breathtaking views of rolling hills and lush valleys. Located in Koraput, it’s a paradise for trekkers and nature lovers seeking serenity and adventure.",
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹899",
      locations: ["Deomali"],
    },
    {
      id: 7,
      title: "Biraja Devi Mandir ",
      image: img7,
      rating: 4,
      description:
        "Biraja Devi Mandir, located in Jajpur, Odisha, is a revered Shakti Peetha dedicated to Goddess Biraja, an incarnation of Goddess Durga. This ancient temple holds great spiritual significance and attracts numerous devotees.",
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹799",
      locations: ["Biraja Devi Mandir"],
    },
  ];

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) =>
        index < rating ? (
          <AiFillStar key={index} className="w-4 h-4 text-yellow-400" />
        ) : (
          <AiOutlineStar key={index} className="w-4 h-4 text-gray-300" />
        )
      );
  };

  return (
    <div className="w-full mx-auto px-4 md:px-12 xl:px-20 pt-5 bg-gradient-to-b from-[#4691F2]/10 to-white">
      <div className="flex justify-between sm:items-center items-start pb-10 sm:flex-row flex-col gap-3">
        <div>
          <h2
            className="sm:text-4xl text-3xl font-medium text-[#082358] mb-3 font-dmserif"
            data-aos="fade-right"
          >
            Popular Places of Odisha !
          </h2>
          <p className="text-[#778088] font-montserrat" data-aos="fade-right">
            Discover curated travel experiences designed to suit every
            <br />
            traveler's interest and budget.
          </p>
        </div>
        <button
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition-colors font-dmsans"
          data-aos="fade-left"
        >
          View All
          <BsArrowRight className="w-4 h-4" />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {places.map((place, index) => (
          <div
            key={index}
            className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg hover:scale-[1.02] transition duration-200"
          >
            <img
              src={place.image}
              alt={place.title}
              className="w-full h-48 object-cover"
              data-aos="flip-right"
            />
            <div className="p-4">
              <div
                className="flex gap-1 mb-2 justify-center items-center"
                data-aos="fade-down"
              >
                {renderStars(place.rating)}
              </div>
              <h3
                className="text-lg font-semibold mb-2 text-center capitalize"
                data-aos="fade-up"
              >
                {place.title}
              </h3>
              <p
                className="text-sm text-gray-600 mb-4 text-center"
                data-aos="fade-right"
              >
                {place.description}
              </p>
              <div
                className="flex justify-center items-center"
                data-aos="fade-down"
              >
                <button className="text-white bg-blue-500 p-2 rounded-full">
                  <BsArrowRight className="w-4 h-4" />
                </button>
              </div>
              <div className="flex justify-between items-center pt-3">
                <div className="flex items-center space-x-4">
                  <div
                    className="flex items-center gap-1 text-sm text-gray-600"
                    data-aos="fade-left"
                  >
                    <FaBus className="w-4 h-4" />
                    <span className="text-[#5C98F2]">Transport Facility</span>
                  </div>
                  <div
                    className="flex items-center gap-1 text-sm text-gray-600"
                    data-aos="fade-right"
                  >
                    <BiTime className="w-4 h-4" />
                    <span className="text-[#5C98F2]">2 days 2 nights</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PopularPlacesOdisha;
