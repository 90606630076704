import React from "react";
import { FaFacebookF, FaPinterestP, FaTwitter } from "react-icons/fa";
import img from "../../../Assets/Images/images.jpg";
import img2 from "../../../Assets/Images/img2.jpg";

const teamMembers = [
  {
    name: "Jane Smith",
    role: "Guider",
    imageUrl: img,
  },
  {
    name: "Marry Deff",
    role: "Guider",
    imageUrl: img2,
  },
  {
    name: "Gren Johnson",
    role: "Guider",
    imageUrl: img,
  },
  {
    name: "Alis Holmes",
    role: "Guider",
    imageUrl: img2,
  },
  {
    name: "Jane Smith",
    role: "Guider",
    imageUrl: img,
  },
  {
    name: "Marry Deff",
    role: "Guider",
    imageUrl: img2,
  },
  {
    name: "Gren Johnson",
    role: "Guider",
    imageUrl: img,
  },
  {
    name: "Alis Holmes",
    role: "Guider",
    imageUrl: img2,
  },
];

const Guides = () => {
  return (
    <section className="py-12 ">
      <h2 className="sm:text-5xl text-3xl font-medium mb-8 text-center font-dmserif text-[#082358]">
        Guides
      </h2>
      <div className="grid grid-cols-1 xl:grid-cols-4 md:grid-cols-2 gap-8 w-full px-4 md:px-12 xl:px-20 mx-auto">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className="text-center border-[1px] border-gray-300 rounded-lg pb-3 shadow-xl"
          >
            <div>
              <img
                src={member.imageUrl}
                alt={member.name}
                className=" w-full h-64 object-cover mb-4"
              />
            </div>
            <h3 className="text-xl font-semibold">{member.name}</h3>
            <p className="text-gray-500">{member.role}</p>
            <div className="flex justify-center space-x-4 mt-4">
              <a href="" className=" hover:text-blue-800">
                <FaFacebookF size={20} />
              </a>
              <a href="" className=" hover:text-red-800">
                <FaPinterestP size={20} />
              </a>
              <a href="" className=" hover:text-blue-600">
                <FaTwitter size={20} />
              </a>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Guides;
