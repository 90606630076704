import React, { useState } from "react";
import img1 from "../../../Assets/Images/person2.jpg";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosStar,
  IoIosStarOutline,
} from "react-icons/io";
import img from "../../../Assets/Images/Ellipse60.png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const testimonials = [
  {
    id: 1,
    name: "Angelie Walter",
    date: "2 October 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img,
  },
  {
    id: 2,
    name: "Manas Bera",
    date: "28 Oct 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img1,
  },
  {
    id: 3,
    name: "Arlene McCoy",
    date: "15 Nov 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img,
  },
];

const Testimonials = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  // const [currentSlide, setCurrentSlide] = useState(0);

  // const nextSlide = () => {
  //   setCurrentSlide((prev) => (prev + 1) % testimonials.length);
  // };

  // const prevSlide = () => {
  //   setCurrentSlide(
  //     (prev) => (prev - 1 + testimonials.length) % testimonials.length
  //   );
  // };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span key={index} className="text-lg">
        {index < rating ? (
          <IoIosStar className="text-yellow-400 text-xl" />
        ) : (
          <IoIosStarOutline className="text-gray-300 text-xl" />
        )}
      </span>
    ));
  };

  return (
    <div className="w-full px-4 md:px-12 xl:px-20 mx-auto  py-12 bg-gradient-to-b from-[#4691F2]/10 to-white">
      <div className="text-center mb-12">
        <h3
          className="text-[#4691F2] font-light mb-2 sm:text-4xl text-3xl font-aguafina tracking-widest"
          data-aos="fade-down"
        >
          Testimonials
        </h3>
        <h2
          className="sm:text-5xl text-4xl  text-[#082358] font-medium font-dmserif max-w-6xl mx-auto"
          data-aos="fade-up"
        >
          What Our Travelers Said about us ?
        </h2>
      </div>

      <div className="relative">
        <div className="flex gap-6 overflow-hidden pb-3 sm:flex-row flex-col">
          {testimonials.map((testimonial, index) => (
            <div
              key={testimonial.id}
              className="w-full md:w-1/3 transition-transform  ease-in-out transform font-opensans hover:scale-[1.02]  duration-200"
            >
              <div className="bg-white p-6 rounded-lg shadow-md">
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    className="w-14 h-14 rounded-full"
                    data-aos="fade-down"
                  />
                  <div>
                    <div className="flex items-center gap-2">
                      <h4 className="font-semibold" data-aos="fade-down">
                        {testimonial.name}
                      </h4>
                      <span
                        className="text-gray-400 text-sm"
                        data-aos="fade-down"
                      >
                        {testimonial.date}
                      </span>
                    </div>
                    <div className="flex" data-aos="fade-right">
                      {renderStars(testimonial.rating)}
                    </div>
                  </div>
                </div>
                <p
                  className="text-gray-600 text-sm leading-relaxed"
                  data-aos="fade-up"
                >
                  {testimonial.text}
                </p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-end items-center gap-3 mt-4">
          <button
            className="rounded-full p-2 bg-[#A7B5F2] text-white"
            data-aos="fade-right"
          >
            <IoIosArrowBack className="w-6 h-6" />
          </button>

          <button
            className="rounded-full p-2 bg-[#A7B5F2] text-white"
            data-aos="fade-left"
          >
            <IoIosArrowForward className="w-6 h-6 " />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
