import React, { useState } from "react";

const FaqsAbout = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Can I get the refund?",
      answer:
        "Yes, you can get a refund according to our refund policy. Please contact our support team.",
    },
    {
      question: "A Toggle Box Title",
      answer: "This is the content for the toggle box.",
    },
    {
      question: "My discount code is not working, what do I do?",
      answer:
        "Please check if the code is entered correctly and still valid. Contact support if issues persist.",
    },
    {
      question: "Do I need to apply visa?",
      answer:
        "Visa requirements vary by country. Please check with the relevant embassy or consulate.",
    },
    {
      question: "Do you have insurance covered?",
      answer:
        "Please contact our support team for detailed information about insurance coverage.",
    },
    {
      question: "How many people can stay in the hotel?",
      answer:
        "The maximum occupancy varies by room type. Please check specific room details.",
    },
    {
      question: "Do you except deposit payment?",
      answer:
        "Yes, we accept deposit payments for certain bookings. Contact us for more details.",
    },
    {
      question: "Which credit cards do you except?",
      answer:
        "We accept major credit cards including Visa, MasterCard, and American Express.",
    },
    {
      question: "Are the tours included with travel fare?",
      answer:
        "Tour inclusion depends on the package you select. Please check package details.",
    },
    {
      question: "Are the tours included with meals?",
      answer:
        "Meal inclusion varies by tour package. Please check the specific tour details.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full mx-auto px-4 md:px-12 xl:px-20 py-8">
      <h1 className="sm:text-5xl text-3xl font-medium mb-8 text-center font-dmserif text-[#082358]">
        Frequently asked questions
      </h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200">
            <button
              className="w-full py-4 flex justify-between items-center text-left text-lg font-medium hover:text-gray-700"
              onClick={() => toggleFAQ(index)}
            >
              <span>{faq.question}</span>
              <span className="text-2xl ml-4">
                {activeIndex === index ? "−" : "+"}
              </span>
            </button>

            <div
              className={`overflow-hidden transition-all duration-300 ease-in-out ${
                activeIndex === index ? "max-h-48" : "max-h-0"
              }`}
            >
              <p className="pb-4 text-gray-600">{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FaqsAbout;
