import React from "react";

const SimpleBanner = ({ image, name, path }) => {
  return (
    <div
      className="h-[350px] flex items-center md:justify-normal justify-center"
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="text-white mx-auto mb-10 font-dmserif">
        <div className="text-2xl md:text-4xl  font-medium tracking-wide capitalize ">
          {name}
        </div>
        <p className="tracking-wider mt-2 capitalize text-md text-center ">
          {path}
        </p>
      </div>
    </div>
  );
};

export default SimpleBanner;
