import React from "react";
import HeroSection from "../../Components/User_Component/Home_Component/HeroSection";
import WhyChooseus from "../../Components/User_Component/Home_Component/WhyChooseus";
import PopularPlacesOdisha from "../../Components/User_Component/Home_Component/Popular_places_odisha";
import National_Packages from "../../Components/User_Component/Home_Component/National_Packages";
import Our_vehicles from "../../Components/User_Component/Home_Component/Our_vehicles";
import Travel_partner from "../../Components/User_Component/Home_Component/Travel_partner";
import AboutUsHome from "../../Components/User_Component/Home_Component/About_us_home";
import Rememberus from "../../Components/User_Component/Home_Component/Rememberus";
import Vehicles from "../../Components/User_Component/Home_Component/Vehicles";
import Testimonials from "../../Components/User_Component/Home_Component/Testinomials";
import LatestBlogs from "../../Components/User_Component/Home_Component/LatestBlogs";

const Home = () => {
  return (
    <div className="overflow-x-hidden">
      <HeroSection />
      <WhyChooseus />
      <PopularPlacesOdisha />
      {/* <National_Packages /> */}
      <Our_vehicles />
      <Travel_partner />
      <AboutUsHome />
      <Rememberus />
      <Vehicles />
      <Testimonials />
      <LatestBlogs />
    </div>
  );
};

export default Home;
