import React from "react";
import TourPhotos from "../../Components/User_Component/Tour_details/TourPhotos";

const Tour_details = () => {
  return (
    <div>
      <TourPhotos />
    </div>
  );
};

export default Tour_details;
