import React from "react";
import car from "../../../Assets/Images/car23.jpg";
import traveller from "../../../Assets/Images/car3.jpg";
import SwiftDzire from "../../../Assets/Images/img24.png";
import traveller2 from "../../../Assets/Images/travellerananyatravels.jpg";
import dieseltank from "../../../Assets/Logos/Frame 74.png";
import seater from "../../../Assets/Logos/Frame 73.png";
import thirdIcon from "../../../Assets/Logos/Frame 72.png";
import tankicon from "../../../Assets/Logos/Frame 71.png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const Our_vehicles = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  const vehicles = [
    {
      image: SwiftDzire,
      name: "Maruti Suzuki Swift Dzire",
      diesel: "Petrol ",
      people: "4 People",
      transmission: "Manual",
      tank: "57 liters",
      price: "₹2500",
    },
    {
      image: car,
      name: "Toyota Innova Crysta",
      diesel: "Diesel",
      people: "7 People",
      transmission: "Manual",
      tank: "57 liters",
      price: "₹2500",
    },
    {
      image: traveller,
      name: "Maruti Suzuki Ertiga",
      diesel: "Diesel",
      people: "7 People",
      transmission: "Manual",
      tank: "57 liters",
      price: "₹2500",
    },
    {
      image: traveller2,
      name: "17 Seater Traveler",
      diesel: "Diesel",
      people: "17 People",
      transmission: "Manual",
      tank: "57 liters",
      price: "₹5000",
    },
  ];

  return (
    <div className="w-full px-4 md:px-12 xl:px-20 mx-auto  py-4 pt-8">
      <div className="text-center mb-8">
        <h1
          className="text-5xl font-medium text-[#082358] mb-2 font-dmserif"
          data-aos="fade-up"
        >
          Our Vehicles
        </h1>
        <p
          className="text-[#778088] font-montserrat text-lg sm:px-[20%] pt-1"
          data-aos="fade-down"
        >
          Explore our top destinations voted by more than 100,000+ customers
          around the world.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {vehicles.map((vehicle, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-md overflow-hidden hover:scale-[1.02] transition duration-200"
          >
            <div className="p-4">
              <img
                src={vehicle.image}
                alt={vehicle.name}
                className="w-full h-52 object-cover mb-4 rounded-lg"
                data-aos="flip-right"
              />
              <h3
                className="text-xl font-semibold text-center pt-2 pb-2 border-b-2 border-[#E5E5E5] font-urbanist"
                data-aos="fade-right"
              >
                {vehicle.name}
              </h3>

              <div className="grid grid-cols-4 gap-0 pt-2 border-[#E5E5E5]">
                <div className="flex flex-col items-center" data-aos="fade-up">
                  <img src={dieseltank} alt="Fuel" className="w-6 h-6 mb-1" />
                  <span className="text-sm text-gray-600">
                    {vehicle.diesel}
                  </span>
                </div>
                <div
                  className="flex flex-col items-center"
                  data-aos="fade-down"
                >
                  <img src={seater} alt="Seats" className="w-6 h-6 mb-1" />
                  <span className="text-sm text-gray-600">
                    {vehicle.people}
                  </span>
                </div>
                <div className="flex flex-col items-center" data-aos="fade-up">
                  <img
                    src={thirdIcon}
                    alt="Transmission"
                    className="w-6 h-6 mb-1"
                  />
                  <span className="text-sm text-gray-600">
                    {vehicle.transmission}
                  </span>
                </div>
                <div
                  className="flex flex-col items-center"
                  data-aos="fade-down"
                >
                  <img src={tankicon} alt="Tank" className="w-6 h-6 mb-1" />
                  <span className="text-sm text-gray-600">{vehicle.tank}</span>
                </div>
              </div>

              {/* <div className="text-center pt-2" data-aos="fade-up">
                <span className="text-[#5C98F2] font-bold text-xl">
                  {vehicle.price}
                </span>
                <span className="text-gray-600 ml-1">/per day</span>
              </div> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Our_vehicles;
