import React from "react";
import img1 from "../../../Assets/Logos/icon-earth.png.png";
import img2 from "../../../Assets/Logos/logo-02.png.png";
import img3 from "../../../Assets/Logos/logo-03.png.png";
// import sideimg1 from "../../../Assets/Images/Image (29).png";
// import sideimg2 from "../../../Assets/Images/Image (30).png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const LastComp = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  const features = [
    {
      icon: img1,
      title: "700 Destinations",
      description: "Our expert team handpicked all destinations in this site",
    },
    {
      icon: img2,
      title: "Best Price Guarantee",
      description: "Price match within 48 hours of order confirmation",
    },
    {
      icon: img3,
      title: "Top Notch Support",
      description:
        "We are here to help, before, during, and even after your trip.",
    },
  ];

  return (
    <div className="w-full mx-auto px-4 md:px-12 xl:px-20 pt-16  pb-16 relative overflow-x-hidden bg-gray-200 mb-6">
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="sm:p-6 p-3 rounded-lg border-gray-200 hover:shadow-lg flex justify-items-start gap-4 hover:scale-110 transition duration-200 font-montserrat hover:border-[#5C98F2] border-[2px]"
          >
            <div className="mb-4">
              <img src={feature.icon} alt="icons" className="h-12 w-20" />
            </div>
            <div>
              <h3 className="text-xl font-medium mb-2">{feature.title}</h3>
              <p className="text-[#778088]">{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LastComp;
