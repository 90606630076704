import { data } from "framer-motion/client";
import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

const Faq = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Can I get the refund?",
      answer:
        "We have you covered! We will email you as items in your order ship, or if there are updates on the status of your order. Can’t find the email? Click here to check the status of your order.",
    },
    {
      question: "Can I change the travel date?",
      answer:
        "Yes, you can request a date change by contacting our support team at least 48 hours before your travel date.",
    },
    {
      question: "My discount code is not working, what do I do?",
      answer:
        "Please ensure the discount code is still valid and has no usage restrictions. If the issue persists, contact customer support.",
    },
    {
      question: "Do I need to apply for a visa?",
      answer:
        "It depends on your destination and nationality. Please consult your local embassy for more details.",
    },
    {
      question: "Do you have insurance covered?",
      answer: "No,We do not provide that.",
    },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  if (!data?.itinerary) {
    return null;
  }

  return (
    <div className="w-full mx-auto pt-10">
      <h1 className="text-3xl font-medium text-[#082358] mb-4 font-dmserif">
        Itinerary
      </h1>
      {data.itinerary.map((faq, index) => (
        <div
          key={index}
          className="border-b mb-4 pb-4 text-[#6C6C6C] font-opensans"
        >
          <div
            className="flex justify-between items-center cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <h2 className="text-lg font-medium">
              Day 0{faq.day}:{faq.title}
            </h2>
            {activeIndex === index ? (
              <AiOutlineMinus className="w-5 h-5 transition-transform transform scale-125" />
            ) : (
              <AiOutlinePlus className="w-5 h-5 transition-transform transform scale-100" />
            )}
          </div>

          <div
            className={`transition-transform ease-in-out transform ${
              activeIndex === index
                ? "scale-y-100 opacity-100"
                : "scale-y-0 opacity-0"
            } origin-top overflow-hidden`}
            style={{
              maxHeight: activeIndex === index ? "200px" : "0",
              transition: "all 0.4s ease",
            }}
          >
            <p className="mt-2 text-gray-600">
              {faq.activities.map((activity, activityIndex) => (
                <span key={activityIndex}>
                  {activity}
                  {activityIndex < faq.activities.length - 1 ? ". " : ""}
                </span>
              ))}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Faq;
