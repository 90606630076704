import React from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { FaBus } from "react-icons/fa";
import { BiTime, BiPhone } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import img1 from "../../../Assets/Images/konark1.webp";
import img from "../../../Assets/Images/image (31).png";
import img2 from "../../../Assets/Images/Chilika.webp";
import img3 from "../../../Assets/Images/jagannath-puri-temple.jpg";
import img4 from "../../../Assets/Images/odisha-tourism-2.png";
import img5 from "../../../Assets/Images/daringbadi.jpg";
import img6 from "../../../Assets/Images/deomali.jpeg";
import img7 from "../../../Assets/Images/BirajaDeviMandir.jpg";

const Tour_comp1 = () => {
  const navigate = useNavigate();

  const places = [
    {
      id: 1,
      title: "Bhubaneswar Konark Puri Chilika Tour Package",
      image: img1,
      rating: 4,
      description:
        "Odisha, a captivating destination, boasts over 7,000 temples, including Lingaraj and Rajarani. Its tropical climate, vibrant atmosphere, and stunning Kalinga architecture make it a spiritual and architectural marvel.",
      duration: "2 days 2 nights",
      facilities: ["Transport Facility"],
      price: "₹999",
      locations: ["Bhubaneswar", "Konark", "Puri", "Chilika"],
    },
    {
      id: 2,
      title: "3N 4D Bhubaneswar Konark Puri Chilika Tour",
      image: img2,
      rating: 4,
      description:
        "All the spectacular tourist destinations in one tour package – that's what we bring to you here. Witness beauty, divinity, wonderful heritage, beaches, good roads and best views, packed in this one-of-its-kind trip!",
      duration: "4 days 3 nights",
      facilities: ["Transport Facility"],
      price: "₹999",
      locations: ["Bhubaneswar", "Konark", "Puri", "Chilika"],
    },
    {
      id: 3,
      title: "Odisha Fixed Departure Tour",
      image: img4,
      rating: 4,
      description:
        "Explore Odisha's iconic temples, including Lingaraj and Rajarani. Witness stunning Kalinga architecture, vibrant culture, and serene landscapes on a well-planned tour offering unforgettable spiritual and cultural experiences.",
      duration: "5 days 4 nights",
      facilities: ["Transport Facility"],
      price: "₹999",
      locations: ["Bhubaneswar", "Puri", "Konark"],
      itinerary: [
        {
          day: 1,
          title: "Bhubaneswar",
          activities: [
            "Reach Bhubaneswar",
            "Check-in at Hotel",
            "Lunch",
            "Visit Parsurameshwar Temple",
            "Visit Mukteshwar Temple",
            "Visit Rajarani Temple",
            "Visit Lingaraj Temple",
          ],
          accommodation: "Overnight Stay at Bhubaneswar",
        },
        {
          day: 2,
          title: "Bhubaneswar - Puri",
          activities: [
            "Visit Khandagiri and Udaigiri Caves",
            "Drive to Puri",
            "Visit Chaushathi Yogini Temple",
            "Visit Dhauli Rock Edicts",
            "Visit Pipli Artisan village",
            "Evening shopping and beach visit",
          ],
          specialNotes:
            "The Caves of Udaigiri and Khandagiri dating back to 3rd Century BC showcase sculptures depicting the social life of that era",
          accommodation: "Overnight stay at Puri",
        },
        {
          day: 3,
          title: "Puri - Konark - Puri",
          activities: [
            "Visit Jagannath Temple",
            "Visit Konark Sun Temple",
            "Visit Chhaitana Stone Carving Village",
            "Visit Chandrabhaga beach",
          ],
          specialNotes:
            "Jagannath Temple is one of the major pilgrimage centers of India. Konark Sun Temple attracts around one lakh devotees every day from all around the world.",
          accommodation: "Overnight stay at Puri",
        },
        {
          day: 4,
          title: "Puri - Chilika Lake",
          activities: [
            "Visit Asia's largest brackish water lake at Chilika",
            "Enjoy boating at the lake to spot wild Asiatic Irrawaddy Dolphins",
            "Visit Alarnath Temple",
            "Visit Raghurajpur Pattachitra Village",
          ],
          accommodation: "Overnight stay at Puri",
        },
        {
          day: 5,
          title: "Departure from Bhubaneswar",
          activities: [
            "After breakfast, check out from hotel",
            "Drop at Airport / Railway station at Bhubaneswar",
          ],
        },
      ],
    },

    {
      id: 4,
      title: "Jagannath Puri Tour Package",
      image: img3,
      rating: 4,
      description:
        "Experience the divine aura of Jagannath Temple, explore serene beaches, and immerse yourself in Odisha's vibrant culture with this thoughtfully crafted tour for a memorable spiritual and cultural journey.",
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹999",
      locations: ["Puri"],
    },
    {
      id: 5,
      title: "Daringbadi Tour Package",
      image: img5,
      rating: 4.5,
      description: `Daringbadi, the "Kashmir of Odisha," is a serene hill station with lush forests, waterfalls, and coffee plantations. Its cool climate and tribal culture make it a perfect getaway for nature lovers.`,
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹899",
      locations: ["Daringbadi"],
    },
    {
      id: 6,
      title: "Deomali Tour Package",
      image: img6,
      rating: 4,
      description:
        "Deomali, the highest peak in Odisha, offers breathtaking views of rolling hills and lush valleys. Located in Koraput, it’s a paradise for trekkers and nature lovers seeking serenity and adventure.",
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹899",
      locations: ["Deomali"],
    },
    {
      id: 7,
      title: "Biraja Devi Mandir Tour Package",
      image: img7,
      rating: 4,
      description:
        "Biraja Devi Mandir, located in Jajpur, Odisha, is a revered Shakti Peetha dedicated to Goddess Biraja, an incarnation of Goddess Durga. This ancient temple holds great spiritual significance and attracts numerous devotees.",
      duration: "2 days 1 night",
      facilities: ["Transport Facility"],
      price: "₹799",
      locations: ["Biraja Devi Mandir"],
    },
  ];

  const handleTourClick = (tourData) => {
    localStorage.setItem("selectedTour", JSON.stringify(tourData));

    navigate(`/tour_details/${tourData?.id}`);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const renderStars = (rating) => {
    return Array(5)
      .fill(0)
      .map((_, index) =>
        index < rating ? (
          <AiFillStar key={index} className="w-4 h-4 text-yellow-400" />
        ) : (
          <AiOutlineStar key={index} className="w-4 h-4 text-gray-300" />
        )
      );
  };

  return (
    <div className="w-full mx-auto px-4 md:px-12 xl:px-20 pt-5 bg-gradient-to-b from-[#4691F2]/10 to-white">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-8 pb-5">
        {places?.map((place) => (
          <div
            key={place?.id}
            className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-lg hover:scale-[1.02] transition duration-200 cursor-pointer"
            onClick={() => handleTourClick(place)}
          >
            <img
              src={place?.image}
              alt={place?.title}
              className="w-full h-48 object-cover"
            />
            <div className="p-4">
              <div className="flex gap-1 mb-2 justify-center items-center">
                {renderStars(place?.rating)}
              </div>
              <h3 className="text-lg font-semibold mb-2 text-center">
                {place?.title}
              </h3>
              <p className="text-sm text-gray-600 mb-4 text-center">
                {place?.description}
              </p>
              <div className="flex justify-center items-center">
                <button className="text-white bg-blue-500 p-2 rounded-full">
                  <BsArrowRight className="w-4 h-4" />
                </button>
              </div>
              <div className="flex justify-between items-center pt-3">
                <div className="flex items-center space-x-4">
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <FaBus className="w-4 h-4" />
                    <span className="text-[#5C98F2]">Transport Facility</span>
                  </div>
                  <div className="flex items-center gap-1 text-sm text-gray-600">
                    <BiTime className="w-4 h-4" />
                    <span className="text-[#5C98F2]">{place?.duration}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tour_comp1;
