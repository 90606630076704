import React from "react";
import NotFound from "../Screens/Common/NotFound";
import Home from "../Screens/User/Home";
import About from "../Screens/User/About";
import Reviews from "../Screens/User/Reviews";
import Tours from "../Screens/User/Tours";
import Vlogs from "../Screens/User/Vlogs";
import Contact from "../Screens/User/Contact"
import Tour_details from "../Screens/User/Tour_details";
import Gallery from "../Screens/User/Gallery";

const userRoutes = [
  { path: "/", component: <Home /> },
  { path: "/about", component: <About /> },
  { path: "/reviews", component: <Reviews /> },
  { path: "/tours", component: <Tours /> },
  { path: "/contact", component: <Contact /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/tour_details/:id", component: <Tour_details /> },
  { path: "*", component: <NotFound /> },
];

export { userRoutes };
