import React from "react";
import bgimage from "../../Assets/Images/FooterBgimg1.png";

const FooterComp1 = () => {
  return (
    <div
      className="relative bg-blue-500 rounded-lg px-8 pt-6  max-w-6xl mx-auto pb-6"
      style={{
        backgroundImage: `url(${bgimage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="flex flex-col md:flex-row items-center justify-around gap-6">
        <div className="text-white font-opensans tracking-wide">
          <h2 className="text-4xl font-semibold">Subscribe for</h2>
          <p className="text-4xl">Latest Newsletter</p>
        </div>

        <div className="flex w-full md:w-auto gap-2 font-montserrat sm:flex-row flex-col">
          <input
            type="email"
            placeholder="Your Email Address"
            className="flex-1 md:w-80 px-4 py-2 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button className="bg-slate-900 text-white px-6 py-2 rounded-xl hover:bg-slate-800 transition-colors whitespace-nowrap">
            Subscribe
          </button>
        </div>
      </div>
    </div>
  );
};

export default FooterComp1;
