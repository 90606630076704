import React from "react";
import img2 from "../../../Assets/Images/person2.jpg";
import {
  IoIosArrowBack,
  IoIosArrowForward,
  IoIosStar,
  IoIosStarOutline,
} from "react-icons/io";
import img from "../../../Assets/Images/Ellipse60.png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";
import { useState } from "react";

const testimonials = [
  {
    id: 1,
    name: "Angelie Walter",
    date: "2 October 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img,
  },
  {
    id: 2,
    name: "Manas Bera",
    date: "2 October 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img2,
  },
  {
    id: 3,
    name: "Arlene McCoy",
    date: "2 October 2022",
    rating: 4,
    text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
    avatar: img,
  },
  // {
  //   id: 4,
  //   name: "Angelie Walter",
  //   date: "2 October 2022",
  //   rating: 4,
  //   text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
  //   avatar: img,
  // },
  // {
  //   id: 5,
  //   name: "Manas Bera",
  //   date: "2 October 2022",
  //   rating: 4,
  //   text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
  //   avatar: img,
  // },
  // {
  //   id: 6,
  //   name: "Arlene McCoy",
  //   date: "2 October 2022",
  //   rating: 4,
  //   text: "The tour was very well organised. One minus is that you get completely bombarded with information. You also have to stand up for too long at the private entrance to the Tower of London, which leads to a lack of time later. Lunch was the same, too stress, the quality was great but you couldn't enjoy it. I'd like to ask the organisers, please.",
  //   avatar: img,
  // },
];

const ratings = [
  { rating: 1 },
  { rating: 2 },
  { rating: 3 },
  { rating: 4 },
  { rating: 5 },
];

const ReviewPage = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    selectedRatings: "",
    message: "",
  });

  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span key={index} className="text-lg">
        {index < rating ? (
          <IoIosStar className="text-yellow-400 text-xl" />
        ) : (
          <IoIosStarOutline className="text-gray-300 text-xl" />
        )}
      </span>
    ));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <div className="w-full px-4 md:px-12 xl:px-20 mx-auto pt-12 bg-gradient-to-b from-[#4691F2]/10 to-white">
      <div className="text-center mb-12">
        <h3
          className="text-[#4691F2] font-light mb-2 sm:text-4xl text-3xl font-aguafina tracking-widest"
          data-aos="fade-down"
        >
          Testimonials
        </h3>
        <h2
          className="sm:text-5xl text-4xl text-[#082358] font-medium font-dmserif max-w-6xl mx-auto"
          data-aos="fade-up"
        >
          What Our Travelers Said about us ?
        </h2>
      </div>

      <div className="relative">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="transition-transform ease-in-out transform hover:scale-[1.02] duration-200 font-opensans"
              data-aos="fade-up"
            >
              <div className="bg-white p-6 rounded-lg shadow-md h-full">
                <div className="flex items-center gap-4 mb-4">
                  <img
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full"
                    data-aos="fade-down"
                  />
                  <div>
                    <div className="flex items-center gap-2">
                      <h4 className="font-semibold" data-aos="fade-down">
                        {testimonial.name}
                      </h4>
                      <span
                        className="text-gray-400 text-sm"
                        data-aos="fade-down"
                      >
                        {testimonial.date}
                      </span>
                    </div>
                    <div className="flex" data-aos="fade-right">
                      {renderStars(testimonial.rating)}
                    </div>
                  </div>
                </div>
                <p
                  className="text-gray-600 text-sm leading-relaxed"
                  data-aos="fade-up"
                >
                  {testimonial.text}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="max-w-3xl mx-auto px-4 pt-12 mb-20 font-opensans">
        <div className="text-center mb-10">
          <h2 className="sm:text-4xl text-3xl font-medium font-dmserif text-[#082358] capitalize">
            Add Your Valuable Feedback
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={(e) =>
                  setFormData({ ...formData, name: e.target.value })
                }
                placeholder="Your Name"
                className="w-full px-4 py-3 border-gray-400 rounded-lg focus:ring-red-500 focus:border-black outline-none transition duration-200 border-[2px]"
                required
              />
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="Your email"
                className="w-full px-4 py-3 border-[2px] border-gray-400 rounded-lg focus:ring-red-500 focus:border-black outline-none transition duration-200"
                required
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={(e) => {
                  const phoneNumber = e.target.value;
                  if (/^\d{0,10}$/.test(phoneNumber)) {
                    setFormData({ ...formData, phone: phoneNumber });
                  }
                }}
                placeholder="Your Number"
                className="w-full px-4 py-3 border-[2px] border-gray-400 rounded-lg focus:ring-red-500 focus:border-black outline-none transition duration-200"
                maxLength={10}
                required
              />
            </div>
            <div className="w-full">
              <select
                value={formData.selectedRatings}
                onChange={(e) =>
                  setFormData({ ...formData, selectedRatings: e.target.value })
                }
                className="w-full border-[2px] border-gray-400 rounded-lg px-2 h-[50px] text-gray-500"
              >
                <option value="">Select a Rating</option>
                {ratings.map((item) => (
                  <option key={item.rating} value={item.rating}>
                    {item.rating} {Array(item.rating).fill("★").join("")}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div>
            <textarea
              name="message"
              value={formData.message}
              onChange={(e) =>
                setFormData({ ...formData, message: e.target.value })
              }
              placeholder="Type message"
              rows={6}
              className="w-full px-4 py-3 border-[2px] border-gray-400 rounded-lg focus:ring-red-500 focus:border-black outline-none transition duration-200"
              required
            />
          </div>
          <div className="text-center">
            <button
              type="submit"
              className="bg-[#4691F2] text-white px-8 py-3 rounded-lg 
                  hover:bg-[#3b87ea] transition duration-200 
                  font-medium"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReviewPage;
