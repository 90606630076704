import React from "react";
import img1 from "../../../Assets/Images/Image131.png";
import img2 from "../../../Assets/Images/vlog2.jpg";
import img3 from "../../../Assets/Images/vlog3.png";
import img4 from "../../../Assets/Images/vlog4.png";
import "aos/dist/aos.css";
import { useEffect } from "react";
import AOS from "aos";

const blogData = [
  {
    id: 1,
    title: "A Lot Of Traveling Turns You Into A Storyteller",
    description:
      "Traveling fills your mind with experiences, adventures, and cultures, transforming every journey into captivating stories worth sharing.",
    image: img1,
  },
  {
    id: 2,
    title: "Journeys Are Best Measured In New Friends",
    description:
      "The true essence of travel lies in the connections made and friendships formed along the way.",
    image: img2,
  },
  {
    id: 3,
    title: "Travel Makes One Modest And Opened To Ideas",
    description:
      "Travel broadens perspectives, humbles the soul, and fosters openness to diverse ideas and new ways of thinking.",
    image: img3,
  },
  {
    id: 4,
    title: "Travel Makes One Modest And Opened To Ideas",
    description:
      "Travel humbles you by revealing the vastness of the world and opens your mind to fresh perspectives and ideas.",
    image: img4,
  },
];

const LatestBlogs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: false,
    });
  }, []);
  return (
    <div className="w-full sm:px-12 xl:px-20 mx-auto px-4 pb-10">
      <div className="text-center mb-12">
        <p
          className="text-blue-500 tracking-widest mb-2 font-aguafina sm:text-4xl text-3xl"
          data-aos="fade-down"
        >
          News & Article
        </p>
        <h2
          className="sm:text-4xl text-3xl font-medium text-[#082358] font-dmserif "
          data-aos="fade-up"
        >
          Our Latest Blogs
        </h2>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {blogData.map((blog) => (
          <div
            key={blog.id}
            className="bg-white rounded-lg shadow-md overflow-hidden transform font-opensans hover:scale-[1.02]  duration-200"
          >
            <div className="relative h-48 overflow-hidden">
              <img
                src={blog.image}
                alt={blog.title}
                className="w-full h-full object-cover"
                data-aos="flip-right"
              />
            </div>
            <div className="p-3">
              <h3
                className="sm:text-xl text-lg font-semibold mb-3 text-gray-900"
                data-aos="fade-down"
              >
                {blog.title}
              </h3>
              <p className="text-gray-600 mb-4 text-sm" data-aos="fade-up">
                {blog.description}
              </p>
              {/* <a
                href="#"
                className="text-blue-500 hover:text-blue-600 font-medium text-sm"
              >
                Read More
              </a> */}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LatestBlogs;
